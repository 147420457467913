import React, { useCallback, useMemo } from 'react';
import {
  stacks,
  Stack,
  StackComponent,
  StackDefinition,
  useCurrentRoute,
  navigate,
  exit,
} from '@navigate';
import {
  useQuery,
  queries,
  StateReferenceQueryVars,
  StateReferenceQueryData,
  HealthExplorerQuery,
  HealthPathwayType,
  useDeprecatedMutation,
} from '@data';
import { Partner, Route } from '@types';
import { ExplorerApplicantsView } from './routes/applicants/ExplorerApplicantsView';
import { ExplorerDoctorsView } from './routes/ExplorerDoctorsView';
import { ExplorerIntroView } from './routes/ExplorerIntroView';
import { ExplorerPlanSelectionView } from './routes/ExplorerPlanSelectionView';
import { ExplorerQLEView } from './routes/ExplorerQLEView';
import { ExplorerRiskView } from './routes/ExplorerRiskView';
import { ExplorerRXView } from './routes/ExplorerRXView';
import { ExplorerSavingsView } from './routes/ExplorerSavingsView';
import { ExplorerSEPIneligibleView } from './routes/ExplorerSEPIneligibleView';
import { ExplorerSEPView } from './routes/ExplorerSEPView';
import { ExplorerStateHandoffView } from './routes/ExplorerStateHandoffView';
import { ExplorerPartnerHandoffView } from './routes/ExplorerPartnerHandoffView';
import { ExplorerStateReturnView } from './routes/ExplorerStateReturnView';
import { ExplorerHouseholdView } from './routes/ExplorerHouseholdView';
import { ExplorerUsageView } from './routes/ExplorerUsageView';
import { ExplorerZipCodeView } from './routes/ExplorerZipCodeView';
import { ExplorerConciergeView } from './routes/ExplorerConciergeView';
import { ExplorerScheduleView } from './routes/ExplorerScheduleView';
import { ExplorerConsentView } from './routes/ExplorerConsentView';
import { Segment } from '@app/utils';
import { useSession } from '@app/hooks';
import { useStackAccentColor } from '@app/hooks';
import { ExplorerAetnaAttestationView } from './routes/ExplorerAetnaAttestationView';
import { ExplorerWindowShoppingView } from './routes/ExplorerWindowShoppingView';

const config: StackDefinition = {
  stackName: stacks.HEALTH_EXPLORER_STACK,
  options: { layout: 'page', navMode: 'flow' },
  screens: [
    ExplorerIntroView,
    ExplorerZipCodeView,
    ExplorerApplicantsView,
    ExplorerStateHandoffView,
    ExplorerPartnerHandoffView,
    ExplorerStateReturnView,
    ExplorerHouseholdView,
    ExplorerSavingsView,
    ExplorerRiskView,
    ExplorerUsageView,
    ExplorerRXView,
    ExplorerDoctorsView,
    ExplorerConciergeView,
    ExplorerScheduleView,
    ExplorerPlanSelectionView,
    ExplorerSEPView,
    ExplorerSEPIneligibleView,
    ExplorerQLEView,
    ExplorerConsentView,
    ExplorerAetnaAttestationView,
    ExplorerWindowShoppingView,
  ],
};

interface ExplorerNavigationProps {
  coverageYear?: number;
  hasQLE?: boolean;
}

/**
 *
 *
 * H X: H E A L T H / E X P L O R E R
 * In-app Health Plan Explorer Stack
 *
 */
const HealthExplorerStack: StackComponent = () => {
  const route = useCurrentRoute();
  const { isConcierge, isFastlane } = useSession();
  const { data } = useQuery(HealthExplorerQuery);
  const accentColor = useStackAccentColor(config.stackName);

  const userID = data?.viewer?.id;

  const {
    explorerID,
    signupCode,
    pathwayType,
    pathway,
    state,
    oe,
    coverageYear,
    oeCoverageYear,
    hasAnyPlans,
    brokerConsent,
    isWindowShopping,
  } = useMemo(
    () => ({
      explorerID: data?.viewerTwo?.healthExplorerData?.id,
      signupCode: data?.viewer?.user?.signupCode,
      coverageYear: data?.viewerTwo?.healthExplorerData?.coverageYear,
      oeCoverageYear: data?.reference?.health?.openEnrollmentDates?.oeCoverageYear,
      pathwayType: data?.viewerTwo?.healthExplorerData?.pathwayType,
      pathway: data?.viewerTwo?.healthExplorerData?.pathway,
      state: data?.viewerTwo?.healthExplorerData?.state || '',
      oe: data?.reference?.health?.openEnrollmentDates,
      hasAnyPlans: (data?.viewerTwo?.healthExplorerData?.healthPlans?.plans || [])?.length > 0,
      brokerConsent: data?.viewer?.user?.agreements?.brokerConsent,
      isWindowShopping: data?.reference?.health?.openEnrollmentDates?.isWindowShopping,
    }),
    [data?.viewerTwo?.healthExplorerData],
  );

  const showWindowShoppingPage = useMemo(
    () => isWindowShopping && pathway === HealthPathwayType.Ffm && coverageYear === oeCoverageYear,
    [pathway, coverageYear, oeCoverageYear, isWindowShopping],
  );

  const [startEnrollment] = useDeprecatedMutation('upsertEnrollmentFromExplorer', {
    onCompleted: (enrollment) => {
      navigate(Route.EDE_INTRO, {
        enrollId: enrollment.id,
        applicationID: enrollment.healthApplication.id,
      });
    },
  });

  // Handles formatting the explorer data for segment tracking
  const trackExplorer = useCallback(() => {
    if (data) {
      Segment.explorerUpdated(data);
    }
  }, [data]);

  // Navigation
  const handleNext = useCallback(
    (data?: ExplorerNavigationProps) => {
      switch (route) {
        case Route.EXPLORER_INTRO:
          navigate(
            explorerID && (isConcierge || isFastlane)
              ? Route.EXPLORER_SCHEDULE
              : Route.EXPLORER_ZIPCODE,
          );
          break;
        case Route.EXPLORER_ZIPCODE:
          const isSEP = data?.coverageYear === oe?.sepYear;
          navigate(
            isSEP && !isConcierge && !isFastlane ? Route.EXPLORER_SEP : Route.EXPLORER_APPLICANTS,
          );
          break;
        case Route.EXPLORER_SEP:
          navigate(Route.EXPLORER_QLE);
          break;
        case Route.EXPLORER_QLE:
          navigate(data?.hasQLE ? Route.EXPLORER_APPLICANTS : Route.EXPLORER_SEP_INELIGIBLE);
          break;
        case Route.EXPLORER_APPLICANTS:
          navigate(Route.EXPLORER_HOUSEHOLD);
          break;
        case Route.EXPLORER_HOUSEHOLD:
          const skippedIncome = data?.income === null;
          if (isFastlane) {
            navigate(Route.EXPLORER_CONCIERGE, data);
          } else if (isConcierge || skippedIncome) {
            navigate(Route.EXPLORER_RISK_TOLERANCE, data);
          } else {
            navigate(Route.EXPLORER_SAVINGS, data);
          }

          break;
        case Route.EXPLORER_SAVINGS:
          navigate(Route.EXPLORER_RISK_TOLERANCE);
          break;
        case Route.EXPLORER_RISK_TOLERANCE:
          navigate(Route.EXPLORER_USAGE);
          break;
        case Route.EXPLORER_USAGE:
          navigate(Route.EXPLORER_PRESCRIPTIONS);
          break;
        case Route.EXPLORER_PRESCRIPTIONS:
          navigate(Route.EXPLORER_DOCTORS);
          break;
        case Route.EXPLORER_DOCTORS:
          const useConcierge = isConcierge || !hasAnyPlans || state === 'MA';
          navigate(useConcierge ? Route.EXPLORER_CONCIERGE : Route.EXPLORER_CHOOSE_PLAN);
          break;
        case Route.EXPLORER_SEP_INELIGIBLE:
          exit(Route.COVERAGE);
          break;
        case Route.EXPLORER_CHOOSE_PLAN:
          const sendToHealthsherpa = signupCode === Partner.decisely;

          if (pathway === HealthPathwayType.Sbm) {
            navigate(
              brokerConsent
                ? showWindowShoppingPage
                  ? Route.EXPLORER_WINDOW_SHOPPING
                  : Route.EXPLORER_STATE_HANDOFF
                : Route.EXPLORER_CONSENT,
            );
          } else if (pathway === HealthPathwayType.Ffm) {
            if (sendToHealthsherpa) {
              navigate(
                brokerConsent
                  ? showWindowShoppingPage
                    ? Route.EXPLORER_WINDOW_SHOPPING
                    : Route.EXPLORER_PARTNER_HANDOFF
                  : Route.EXPLORER_CONSENT,
              );
            } else {
              if (showWindowShoppingPage) {
                navigate(brokerConsent ? Route.EXPLORER_WINDOW_SHOPPING : Route.EXPLORER_CONSENT);
              } else {
                startEnrollment();
              }
            }
          }

          break;
        case Route.EXPLORER_CONSENT:
          if (showWindowShoppingPage) {
            navigate(Route.EXPLORER_WINDOW_SHOPPING);
          } else if (pathway === HealthPathwayType.Sbm) {
            navigate(Route.EXPLORER_STATE_HANDOFF);
          } else if (pathway === HealthPathwayType.Ffm) {
            if (signupCode === Partner.decisely) {
              navigate(Route.EXPLORER_PARTNER_HANDOFF);
            } else {
              navigate(Route.EDE_INTRO);
            }
          }
          break;
        default:
          break;
      }
    },
    [route, oe, isConcierge, hasAnyPlans, state, pathway, brokerConsent, isWindowShopping],
  );

  const stackData = useMemo(() => {
    return {
      userID,
      explorerID,
      pathwayType,
      pathway,
      coverageYear,
      signupCode,
      trackExplorer,
      handleNext,
      accentColor,
    };
  }, [pathwayType, pathway, coverageYear, signupCode, handleNext, trackExplorer]);

  // fetches this whenever the state changes so we have it
  useQuery<StateReferenceQueryData, StateReferenceQueryVars>(queries.STATE_REFERENCE, {
    variables: { state },
    skip: !state,
  });

  return (
    <Stack
      stackName={config.stackName}
      screens={config.screens}
      options={{
        ...config.options,
        accentColor: accentColor || 'coverage',
      }}
      data={stackData}
    />
  );
};

HealthExplorerStack.config = config;
export default HealthExplorerStack;
