// import { chooseHealthApp } from '@app/utils';
import { PromptName } from '@types';
import type { Situation } from '@types';
import { Prompts } from '@app/config/prompts';
import { chooseHealthApp } from './chooseHealthApp';
import { HomeQueryData, IncomeStatus, PagedIncomeQuery } from '@app/data';

interface Prompt {
  name: PromptName;
  data?: {};
}

const applyPrompt = (promptName, data) => {
  const config = Prompts[promptName];

  if (!config) console.error('MISSING PROMPT', promptName);
  return typeof config === 'function' ? config(data) : config;
};

const getCurrentApplications = (data, year) => {
  return (data?.viewerTwo?.health?.applications || data?.viewer?.health?.applications || []).filter(
    (app) => app.coverageYearNumber === year,
  );
};

const getCurrentPolicies = (data, year) => {
  return (data?.viewerTwo?.health?.policies || []).filter((policy) => policy.coverageYear === year);
};

/**
 * Given query data and a list of prompts to show,
 * we return computed set of prompts
 */
export const getPrompts = (
  data: HomeQueryData,
  fintechData: PagedIncomeQuery | undefined,
  { showPrompts = Object.keys(PromptName), isDebug },
): Array<Prompt> => {
  const displayPrompts: Array<Prompt> = [];

  const openEnrollmentDates = data?.reference?.health?.openEnrollmentDates;

  const coverageYear =
    openEnrollmentDates.isOpenEnrollment || openEnrollmentDates?.isWindowShopping
      ? openEnrollmentDates?.oeCoverageYear
      : openEnrollmentDates?.sepCoverageYear;

  const currentApps = getCurrentApplications(data, coverageYear);

  const currentPolicies = getCurrentPolicies(data, coverageYear);

  const incomes = fintechData?.pagedIncome?.edges || [];

  const v = data?.viewer;
  const v2 = data?.viewerTwo;
  const u = v?.user;

  const idpStatus = v2?.cmsIdentity?.status;
  const dismissedNudges = data?.viewer?.nudges?.filter((n) => n?.isDismissed)?.map((n) => n.id);

  const oeDates = data?.reference?.health?.openEnrollmentDates || {};

  // prefer whats in the explorer currently over any other fallback
  const preferredPathways = v2?.healthExplorerData?.pathwayType
    ? [v2?.healthExplorerData?.pathwayType]
    : u?.supportedPathways;

  // determines situations based on passed data
  const situations: Record<Situation, boolean> = {
    /** HEALTH */
    FFM_STATE: preferredPathways?.some((pathway) => /EDE/.test), //wtf?
    KNOWN_NON_FFM: preferredPathways?.includes('STATE_EXCHANGE'),
    ASSUMED_FFM: preferredPathways === null || preferredPathways?.includes('EDE'),

    HEALTH: v2?.health?.applications?.length > 0,

    /** OPEN ENROLLMENT */
    OPEN_ENROLLMENT: oeDates?.isOpenEnrollment,
    NOT_OPEN_ENROLLMENT: !oeDates?.isOpenEnrollment,
    WINDOW_SHOPPING: oeDates?.isWindowShopping,
    NOT_WINDOW_SHOPPING: !oeDates?.isWindowShopping,

    NO_CURRENT_APPS: currentApps?.length === 0,
    NO_CURRENT_POLICIES: currentPolicies?.length === 0,
    HAS_ENROLLED:
      currentPolicies?.length > 0 || currentApps?.some((app) => app.status === 'ENROLLED'),
    HAS_NOT_ENROLLED:
      currentPolicies?.length === 0 && currentApps?.every((app) => app.status !== 'ENROLLED'),

    /** INCOME */
    INCOME: incomes?.length > 0,
    NO_INCOME: incomes?.length === 0,

    /** currently unused/todo */
    MANUAL_ONLY: false,
    NO_HEALTH: false,
    NEED_HEALTH_DOCS: false,
    HEALTH_IN_PROGRESS: false,
    MANUAL_ID_REVIEW: idpStatus === 'PENDING_MANUAL_REVIEW',
    DOCS_AVAILABLE: data?.viewerTwo?.documents?.taxDocuments?.length > 0,
  };

  // find the best app to show to user
  const bestApp = chooseHealthApp(currentApps);

  if (showPrompts.includes(PromptName.CONTINUE_APP) && bestApp?.status === 'IN_PROGRESS') {
    displayPrompts.push(applyPrompt(PromptName.CONTINUE_APP, { app: bestApp, oeDates }));
  }

  if (showPrompts.includes(PromptName.FINISH_ENROLLING) && bestApp?.status === 'SUBMITTED') {
    displayPrompts.push(applyPrompt(PromptName.FINISH_ENROLLING, { app: bestApp }));
  }

  /**
   * situational prompts
   * e.g. if situations apply, we show the prompt
   */
  showPrompts.forEach((name) => {
    const prompt = applyPrompt(name, data);

    // for each singleton prompt
    if (prompt && !prompt.forEach && !dismissedNudges?.includes(prompt.name)) {
      if (isDebug || prompt.dependencies.every((dep) => situations[dep])) {
        displayPrompts.push(prompt);
      }
    }
  });

  if (showPrompts.includes(PromptName.NEEDS_HEALTH_DOCS)) {
    // health docs
    currentApps.forEach((app) => {
      const issues = [...(app?.dmis || []), ...(app?.svis || [])].filter(
        (issue) => issue.status === 'ACTION_NEEDED' || issue.status === 'PROCESSING',
      );

      const actionNeeded = issues.some((issue) => issue?.status === 'ACTION_NEEDED');

      if (issues.length > 0) {
        displayPrompts.push(
          applyPrompt(PromptName.NEEDS_HEALTH_DOCS, {
            actionNeeded,
            applicationID: app?.id,
            deadline: Date.parse(issues?.[0]?.resolveBy),
          }),
        );
      }
    });
  }

  // payment redirect
  if (showPrompts.includes(PromptName.PAYMENT_REDIRECT)) {
    currentApps.forEach((app) => {
      const policies = [...(app?.policies || [])]
        ?.filter((p) => p.dueDate >= Date.now())
        .sort((a, b) => (a?.policy?.productType > b?.policy?.productType ? 1 : -1));

      policies.forEach((policy) => {
        if (policy.policyStatus === 'ACTIVE_PENDING_PAYMENT') {
          displayPrompts.push(
            applyPrompt(PromptName.PAYMENT_REDIRECT, { policy, enrollment: app.enrollment }),
          );
        }
      });
    });
  }

  if (situations.INCOME && showPrompts.includes(PromptName.INCOME)) {
    incomes?.forEach((income) => {
      if (income?.status === IncomeStatus.Ready) {
        const prompt = applyPrompt(PromptName.INCOME, { income });
        displayPrompts.push(prompt);
      }
    });
  }

  return displayPrompts;
};
