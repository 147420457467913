import { optionPresets } from '@app/forms';
import { currentYear, Env } from '@app/utils';

const PREFIX = 'catch.ede.HealthEnrollment';

export const fields = [
  {
    copy: PREFIX,
    name: 'isRenewalAgreement',
    type: 'radio',
    required: true,
    options: optionPresets.AGREE_DISAGREE_TRINARY,
    dependencies: ['isRequestingFinancialAssistance'],
    display: ({ isRequestingFinancialAssistance }) => !!isRequestingFinancialAssistance,
    label: { id: '252_Q' },
    help: { id: '252_T' },
    testID: '252',
  },
  {
    copy: PREFIX,
    name: 'renewEligibilityYearQuantity',
    type: 'dropdown',
    required: true,
    options: optionPresets.RENEWAL_YEARS,
    dependencies: ['isRenewalAgreement'],
    display: ({ isRenewalAgreement }) => isRenewalAgreement === 'NO',
    label: { id: '253_Q' },
    help: { id: '253_T' },
    testID: '253',
  },
  {
    copy: PREFIX,
    name: 'isMedicaidRequirementAgreement',
    type: 'radio',
    required: true,
    options: optionPresets.AGREE_DISAGREE_TRINARY,
    dependencies: ['members', 'isResubmit'],
    // until we know that prelim flags are set properly via the import,
    // we should show this question anytime we're in the resubmit style flow
    display: ({ members, isResubmit }) =>
      isResubmit ||
      members?.some(
        (dep) =>
          dep?.preliminaryMedicaidStatus === 'YES' ||
          (dep?.preliminaryEmergencyMedicaidStatus === 'YES' &&
            dep?.preliminaryMedicaidStatus === 'NO'),
      ),
    label: { id: '247_Q' },
    help: { id: '247_T' },
    testID: '247',
  },
  {
    copy: PREFIX,
    name: 'isAbsentParentAgreement',
    type: 'radio',
    required: true,
    options: optionPresets.AGREE_DISAGREE_TRINARY,
    dependencies: ['members'],
    display: ({ members }) => {
      const childDependents = members?.filter((d) => d?.relation === 'CHILD');
      return childDependents?.some((dep) => !!dep?.hasAbsentParent);
    },
    label: { id: '248_Q' },
    help: { id: '248_T' },
    testID: '248',
  },
  {
    copy: PREFIX,
    name: 'isTerminateCoverageOtherMecFoundAgreement',
    type: 'radio',
    required: true,
    dependencies: ['members', 'isGA'],
    options: ({ isGA }) => [
      {
        label: isGA
          ? 'I agree to allow Georgia Access to end Georgia Access coverage for anyone on my application who’s enrolled in both Georgia Access and Medicare coverage.'
          : 'I agree to allow the Marketplace to end the Marketplace coverage for anyone on my application who’s enrolled in both Marketplace and Medicare coverage.',
        value: 'YES',
      },
      {
        label: isGA
          ? '​I don’t give Georgia Access permission to end Georgia Access coverage for anyone on my application who’s enrolled in both Georgia Access and Medicare coverage. I understand that they will have a Georgia Access health plan without financial help and pay the full cost for their share of the Georgia Access plan premium and covered services, if eligible.'
          : '​I don’t give the Marketplace permission to end Marketplace coverage for anyone on my application who’s enrolled in both Marketplace and Medicare coverage. I understand that they will have a Marketplace health plan without financial help and pay the full cost for their share of the Marketplace plan premium and covered services, if eligible.',
        value: 'NO',
      },
    ],
    display: ({ members }) => members?.some((dep) => dep?.preliminaryQHPStatus === 'YES'),
    // @OE24 :: remove prod only copy
    label: { id: Env.isProd ? '255_Q_PROD' : '255_Q' },
    help: ({ isGA }) => ({ id: `255_T${isGA ? '_GA' : ''}` }),
    testID: '255',
  },
  {
    copy: PREFIX,
    name: 'isChangeInformationAgreement',
    type: 'radio',
    required: true,
    options: optionPresets.AGREE_DISAGREE_TRINARY,
    label: { id: '254_Q' },
    help: { id: '254_T' },
    testID: '254',
    dependencies: ['isChangeInformationAgreement'],
    fieldAddition: ({ isChangeInformationAgreement }) => {
      if (isChangeInformationAgreement === 'NO') {
        return {
          component: 'WARNING',
          props: { copy: { id: 'ede.HealthEnrollment.257_T' } },
        };
      }
    },
  },
  {
    copy: PREFIX,
    name: 'GA_SIG_1',
    type: 'radio',
    options: [{ label: 'I agree to this statement', value: true }],
    required: true,
    dependencies: ['isGA'],
    display: ({ isGA }) => isGA,
    label: { id: 'GA_SIG_1' },
  },
  {
    copy: PREFIX,
    name: 'GA_SIG_2',
    type: 'radio',
    options: [{ label: 'I agree to this statement', value: true }],
    required: true,
    dependencies: ['isGA'],
    display: ({ isGA }) => isGA,
    label: { id: 'GA_SIG_2' },
  },
  {
    copy: PREFIX,
    name: 'isTaxCreditReconciled',
    type: 'checkbox',
    required: false,
    optional: true,
    dependencies: ['legalName', 'preliminaryAPTCStatus', 'year1', 'year2'],
    display: ({ preliminaryAPTCStatus }) => preliminaryAPTCStatus === 'YES',
    precontentTitle: ({ legalName, year1, year2 }) => ({
      id: '236_Q',
      data: { fullName: legalName, year1, year2, lastYear: currentYear - 1 },
    }),
    label: ({ year1, year2 }) => ({
      id: '236_Q_label',
      data: { year1, year2 },
    }),
    help: { id: '236_T' },
    testID: '236',
  },
  {
    copy: PREFIX,
    name: 'isPenaltyOfPerjuryAgreement',
    type: 'checkbox',
    required: true,
    dependencies: ['isGA'],
    label: ({ isGA }) => ({ id: isGA ? '256_Q_GA' : '256_Q' }),
    testID: '256',
  },
  {
    copy: PREFIX,
    name: 'signatureText',
    type: 'signature',
    dependencies: ['legalName'],
    required: true,
    label: { id: '258_Q' },
    testID: '258',
  },
];
