import React from 'react';
import { Copy } from '@types';
import { InputProps } from '../types';
import { Checkbox } from '@app/_ui-kit';

interface CheckboxInputProps<T> extends InputProps<T> {
  title?: Copy;
  label: Copy;
  plain?: boolean; // when set, show the checkbox without the card selection
  scroll?: number;
  ariaLabel?: string;
  optional?: boolean;
  precontentTitle?: Copy;
}

const CheckboxInput = <T extends any>({
  testID,
  name,
  label,
  title,
  value,
  help,
  disabled,
  onChange,
  onHover,
  onFocus,
  onBlur,
  scroll,
  plain,
  ariaLabel,
  optional,
  precontentTitle,
}: CheckboxInputProps<T>) => {
  return (
    <Checkbox
      testID={testID}
      name={name}
      value={value}
      disabled={disabled}
      onHover={onHover}
      onFocus={onFocus}
      onChange={(val) => {
        onChange(val);
        onBlur();
      }}
      label={label}
      title={title}
      precontentTitle={precontentTitle}
      help={help}
      scroll={scroll}
      plain={plain}
      ariaLabel={ariaLabel}
      optional={optional}
    />
  );
};

export default CheckboxInput;
