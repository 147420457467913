import { useEffect, useState } from 'react';
import { Loading } from '@uikit';
import { history, navigate, ScreenDefinition } from '@navigate';
import { Partner, Route } from '@types';
import { useSignupParams } from '@app/auth/useSignupParams';
import {
  useMutation,
  HealthPathwayType,
  IMPORT_PUBLIC_EXPLORER,
  useQuery,
  GET_CURRENT_USER,
  updates,
} from '@app/data';
import { ALREADY_AETNA } from '@app/constants/aetna';

type WelcomeStatus = 'loading' | 'done';

export const Welcome = () => {
  const [status, setStatus] = useState<WelcomeStatus>('loading');
  const { isOnboarding, params, clearParams } = useSignupParams();

  const user = useQuery(GET_CURRENT_USER);

  const [importPX] = useMutation(IMPORT_PUBLIC_EXPLORER, {
    update: (cache, result) => updates.UPDATE_EXPLORER(cache, result),
    onCompleted: (data) => {
      const explorer = data.importExplorerFromPublic;
      const sendToHealthsherpa = user.data?.me.user.signupCode === Partner.decisely;
      const brokerConsent = user?.data?.me?.user?.agreements?.brokerConsent;

      if (!explorer.providerPlanID) {
        goTo(Route.EXPLORER_CHOOSE_PLAN);
      } else if (explorer.pathway === HealthPathwayType.Sbm) {
        goTo(brokerConsent ? Route.EXPLORER_STATE_HANDOFF : Route.EXPLORER_CONSENT);
      } else if (explorer.canCreateEnrollment) {
        if (sendToHealthsherpa) {
          goTo(brokerConsent ? Route.EXPLORER_PARTNER_HANDOFF : Route.EXPLORER_CONSENT);
        } else {
          goTo(Route.EDE_INTRO);
        }
      } else {
        goTo(Route.EXPLORER_ZIPCODE);
      }
    },
    onError: () => {
      console.warn('Something went wrong, going to home page');
      goTo(Route.HOME);
    },
  });

  const goTo = (route: Route, data?: object) => {
    setStatus('done');

    setTimeout(() => {
      clearParams();
      navigate(route, data);
    }, 1500);
  };

  useEffect(() => {
    if (params?.ask === ALREADY_AETNA) {
      goTo(Route.SIGNUP_AETNA_ATTESTATION);
    } else if (params.returnTo) {
      history.push(params.returnTo);
      clearParams();
    } else if (params.hid) {
      importPX({ variables: { hid: params.hid } });
    } else if (isOnboarding) {
      goTo(Route.EXPLORER_ZIPCODE);
    } else {
      goTo(Route.HOME);
    }
  }, []);

  return (
    <Loading<WelcomeStatus>
      page
      full
      titles={{
        loading: 'Loading...',
        done: isOnboarding ? 'Welcome to Catch!' : 'Welcome back!',
      }}
      status={status}
    />
  );
};

export const WelcomeView: ScreenDefinition = {
  name: Route.WELCOME,
  component: Welcome,
  options: {},
};
