import React, { useState, useMemo } from 'react';
import { View, LayoutEvent } from 'react-native';
import { Controller } from 'react-hook-form';
import { FieldConfig } from '@types';
import { useTheme } from '@uikit/hooks/useTheme';
import { FormLabel } from '@uikit/components/FormLabel';
import { createLogger } from '@app/utils/logger';
import { createRulesObject } from '../utils/formValidation';

// input components
import OptionInput from '../inputs/OptionInput';
import TextInput from '../inputs/TextInput';
import AmountInput from '../inputs/AmountInput';
import MultiSelectInput from '../inputs/MultiSelectInput';
import StepperInput from '../inputs/StepperInput';
import DropdownInput from '../inputs/DropdownInput';
import DateInput from '../inputs/DateInput';
import AddressInput from '../inputs/AddressInput';
import LegalNameInput from '../inputs/LegalNameInput';
import SignatureInput from '../inputs/SignatureInput';
import CheckboxInput from '../inputs/CheckboxInput';
import PhoneInput from '../inputs/PhoneInput';
import SSNInput from '../inputs/SSNInput';
import RadioInput from '../inputs/RadioInput';
import EmailInput from '../inputs/EmailInput';
import NumberInput from '../inputs/NumberInput';
import ZipInput from '../inputs/ZipInput';
import FileInput from '../inputs/FileInput';
import AliasInput from '../inputs/AliasInput';
import PasswordInput from '../inputs/PasswordInput';
import PersonRowInput from '../inputs/PersonRowInput';
import RowInput from '../inputs/RowInput';
import TextareaInput from '../inputs/TextareaInput';
import Fieldset from '../inputs/FieldsetInput';

import FieldAddition from './FieldAddition';
import { useDependentValues } from '../utils/useDependentValues';
import { dynamic } from '../utils/dynamicConfig';
import { optionPresets } from '../config/options';
import { useApplyCopy } from '@util/language/useApplyCopy';
import { useCopy } from '@app/utils';
import { trimValue } from '../utils/trimValue';

const Log = createLogger('Field');

interface FieldProps {
  control: any;
  config: FieldConfig;
  autoSubmit: boolean;
  isFirst: boolean;
  isLast: boolean;
  disabled: boolean;
  hideLabel: boolean;
  reset: () => void;
  submitForm: () => void;
  goToNext: () => void;
  setCurrentField: (name: string) => void;
  setCurrentHelpText: (text?: string) => void;
  setLocation: (field: string, layout: LayoutEvent['layout']) => void;
  containerStyle?: object;
  ariaContext?: string;
}

const Field: React.FC<FieldProps> = React.forwardRef(
  (
    {
      config,
      control,
      autoSubmit,
      isFirst,
      isLast,
      disabled,
      hideLabel,
      reset,
      goToNext,
      submitForm,
      setCurrentField,
      setCurrentHelpText,
      setLocation,
      containerStyle,
      values,
      remove,
      ariaContext,
    },
    ref,
  ) => {
    // shows some toggle component instead of field
    const { Toggle } = config;
    const [showToggle, setToggle] = useState(!!Toggle);

    // applies copy to field text
    const { locale } = useCopy();
    const { c } = useCopy('catch');

    const applyCopy = useApplyCopy(config.copy || 'catch');

    const { theme } = useTheme();

    const dependentValues = useDependentValues({
      dependencies: config.dependencies || [],
      control,
      prefix: config?.prefix,
    });

    const required = useMemo(() => {
      return dynamic.required(config, dependentValues);
    }, [dependentValues]);

    const doesNotExistIn = useMemo(() => {
      return dynamic.doesNotExistIn(config, dependentValues);
    }, [dependentValues]);

    // determines whether this field should be visible or hidden
    const showField = useMemo(() => {
      return dynamic.display(config, dependentValues);
    }, [dependentValues]);

    //allows for dynamic labeling on forms
    const label = useMemo(() => {
      return dynamic.label(config, dependentValues, hideLabel);
    }, [dependentValues, hideLabel, locale]);

    const optional = useMemo(() => {
      return dynamic.optional(config, dependentValues);
    }, [dependentValues, config]);

    const sublabel = useMemo(() => {
      return dynamic.sublabel(config, dependentValues, hideLabel);
    }, [dependentValues, hideLabel, locale]);

    const secondary = useMemo(() => {
      return dynamic.secondary(config, dependentValues);
    }, [dependentValues]);

    const headerText = useMemo(() => {
      if (typeof config.headerText === 'function') return config.headerText(dependentValues);
      return config.headerText;
    }, [dependentValues, locale]);

    const help = useMemo(() => {
      if (typeof config.help === 'function') return config.help(dependentValues);
      return config.help;
    }, [dependentValues, locale]);

    const precontentTitle = useMemo(() => {
      if (typeof config.precontentTitle === 'function')
        return config.precontentTitle(dependentValues);
      return config.precontentTitle;
    }, [dependentValues, locale]);

    const disabledFields = useMemo(() => {
      if (typeof config.disabledFields === 'function')
        return config.disabledFields(dependentValues);
      return config.disabledFields;
    }, [dependentValues]);

    const legalName = useMemo(() => {
      return dependentValues.legalName || config.legalName;
    }, [dependentValues]);

    const options = useMemo(() => {
      const opts =
        typeof config.options === 'function'
          ? config.options(dependentValues)
          : config.options || [];
      return opts.map((option) => ({
        ...option,
        label: applyCopy(option.label),
      }));
    }, [dependentValues, locale]);

    //allow for dynamic max value, e.g. in a stepper input, disable button when exceeds max allowed value
    const max = useMemo(() => {
      if (typeof config.max === 'function') {
        return config.max(dependentValues);
      }

      return config.max;
    }, [dependentValues]);

    const allowNegative = useMemo(() => {
      if (typeof config.allowNegative === 'function') {
        return config.allowNegative(dependentValues);
      }
      return config.allowNegative;
    }, [dependentValues]);

    const documentType = useMemo(() => {
      if (typeof config.documentType === 'function') {
        return config.documentType(dependentValues);
      }

      return config.documentType;
    }, [dependentValues]);

    const filePrefix = useMemo(() => {
      return dynamic.filePrefix(config, dependentValues);
    }, [dependentValues]);

    // computes the rules for this field
    const rules = useMemo(() => {
      return createRulesObject({
        ...config,
        allowNegative,
        legalName,
        required,
        doesNotExistIn,
        max,
      });
    }, [required, legalName, config, allowNegative, doesNotExistIn, max]);

    const horizontal = useMemo(() => {
      let parsed;
      try {
        parsed = JSON.stringify(options);
      } catch (e) {}

      if (
        parsed === JSON.stringify(optionPresets.YES_NO_BOOLEAN) ||
        parsed === JSON.stringify(optionPresets.YES_NO_TRINARY) ||
        parsed === JSON.stringify(optionPresets.AGREE_DISAGREE_BOOLEAN) ||
        parsed === JSON.stringify(optionPresets.AGREE_DISAGREE_TRINARY) ||
        parsed === JSON.stringify(optionPresets.SEX)
      ) {
        return true;
      }

      // check if options are YES/NO
      return config?.horizontal;
    }, [options, config.horizontal]);

    const defaultValue = useMemo(() => {
      if (config.type === 'address') {
        return {};
      }

      if (config.type === 'legalName') {
        return Object.keys(config.subfields).reduce((vals, subfield) => {
          return config.subfields[subfield] ? { ...vals, [subfield]: '' } : vals;
        }, {});
      }

      return config.defaultValue;
    }, [config]);

    /** Compute dynamic values */
    const computedValue = useMemo(() => {
      return dynamic.value(config, dependentValues);
    }, [config.value, dependentValues]);

    /** Create composite contextual aria label to differentiate input groups */
    const contextualAriaLabel = ariaContext
      ? `${ariaContext} - ${applyCopy(label)}`
      : applyCopy(label);

    const addition = config.fieldAddition ? config.fieldAddition(dependentValues) : null;

    if (!showField) {
      return null;
    }

    const handleDone = () => {
      if (isLast) {
        if (submitForm) {
          submitForm();
        }
      } else {
        if (goToNext) {
          goToNext();
        }
      }
    };

    return (
      <View
        onLayout={({ nativeEvent }) => {
          if (setLocation) {
            setLocation(config.name, nativeEvent.layout);
          } else {
            Log.warn(`${config.name} is missing setLocation()`);
          }
        }}
        style={[theme.fadeIn, !config.bare && containerStyle]}
      >
        <Controller
          control={control}
          name={config.name}
          rules={rules}
          defaultValue={defaultValue}
          shouldUnregister
          render={({ field, fieldState }) => {
            const inputProps = {
              ref,
              testID: config.testID || config.name,
              name: config.name,
              /** Set computed values defined by functions */
              value: computedValue !== undefined ? computedValue : field.value,
              autoFocus: isFirst,
              disabled, // could also specifically disable individual fields?
              onChange: (val) => {
                if (computedValue === undefined) {
                  field.onChange(val);
                }
              },
              onHover: () => {
                if (setCurrentField) {
                  setCurrentField(config.name);
                }

                if (setCurrentHelpText) {
                  setCurrentHelpText(applyCopy(help));
                }
              },
              onFocus: () => {
                if (setCurrentField) {
                  setCurrentField(config.name);
                }

                if (setCurrentHelpText) {
                  setCurrentHelpText(applyCopy(help));
                }
              },
              onBlur: () => {
                if (setCurrentHelpText) {
                  setCurrentHelpText();
                }

                field.onBlur();
              },
              onBlurText: () => {
                if (setCurrentHelpText) {
                  setCurrentHelpText();
                }

                if (field?.value) {
                  const trimmed = trimValue(field?.value);
                  field.onChange(trimmed);
                }

                field.onBlur();
              },
              onKeyPress: (key) => {
                if (key === 'Enter') {
                  submitForm();
                }
              },
              onEndEditing: handleDone,
              onSubmitEditing: handleDone,
              reset,
              label: applyCopy(label),
              sublabel: applyCopy(sublabel),
              help: applyCopy(help),
              optional: !required && optional !== false,
              placeholder: applyCopy(config.placeholder),
              error: fieldState.isTouched ? fieldState.error?.message : undefined,
              subtext: config.subtext,
              large: config.large,
              ariaLabel: contextualAriaLabel,
            };

            if (showToggle) {
              return <Toggle showField={() => setToggle(false)} />;
            }

            if (config.type === 'label') {
              return <FormLabel {...inputProps} />;
            }

            if (config.type === 'option') {
              return (
                <OptionInput
                  {...inputProps}
                  options={options}
                  horizontal={horizontal}
                  small={config.small}
                  onChange={(val) => {
                    field.onChange(val);

                    //more so used for some option forms that don't autosubmit and pop up a sheet instead
                    if (config.onPress) {
                      config.onPress(val);
                    }

                    if (autoSubmit) {
                      submitForm();
                    }

                    field.onBlur();
                  }}
                />
              );
            }

            if (config.type === 'text') {
              return (
                <TextInput
                  {...inputProps}
                  onBlur={inputProps.onBlurText}
                  keyboard={config.keyboard}
                  format={config.format}
                  characters={config.characters}
                  length={config.length}
                />
              );
            }

            if (config.type === 'textarea') {
              return (
                <TextareaInput
                  {...inputProps}
                  onBlur={inputProps.onBlurText}
                  rows={config.rows}
                  placeholder={config.placeholder}
                  readOnly={config.readOnly}
                />
              );
            }

            if (config.type === 'fieldset') {
              return (
                <Fieldset
                  label={applyCopy(label)} // Renders as <legend>
                  sublabel={applyCopy(sublabel)}
                  optional={optional}
                  ariaLabel={contextualAriaLabel}
                >
                  {config.fields?.map((fieldConfig) => (
                    <Field
                      key={fieldConfig.name}
                      config={fieldConfig}
                      control={control}
                      autoSubmit={autoSubmit}
                      isFirst={false}
                      isLast={false}
                      disabled={disabled}
                      hideLabel={hideLabel}
                      reset={reset}
                      submitForm={submitForm}
                      goToNext={goToNext}
                      setCurrentField={setCurrentField}
                      setCurrentHelpText={setCurrentHelpText}
                      setLocation={setLocation}
                      containerStyle={containerStyle}
                      values={values}
                      remove={remove}
                      ariaContext={ariaContext}
                    />
                  ))}
                </Fieldset>
              );
            }

            if (config.type === 'checkbox') {
              return (
                <CheckboxInput
                  {...inputProps}
                  scroll={config.scroll}
                  plain={config.plain}
                  title={applyCopy(config.title)}
                  precontentTitle={applyCopy(precontentTitle)}
                />
              );
            }

            if (config.type === 'amount') {
              return <AmountInput {...inputProps} amountType={config.amountType} />;
            }

            if (config.type === 'number') {
              return <NumberInput {...inputProps} numberType={config.numberType} />;
            }

            if (config.type === 'zip') {
              return (
                <ZipInput {...inputProps} onBlur={inputProps.onBlurText} large={config.large} />
              );
            }

            if (config.type === 'multiSelect') {
              return <MultiSelectInput {...inputProps} options={options} style={config.style} />;
            }

            if (config.type === 'signature') {
              return (
                <SignatureInput
                  {...inputProps}
                  onBlur={inputProps.onBlurText}
                  legalName={legalName}
                />
              );
            }

            if (config.type === 'stepper') {
              const ariaLabelDecrease = c('basics.decrease', {
                label: label,
              });
              const ariaLabelIncrease = c('basics.increase', {
                label: label,
              });

              return (
                <StepperInput
                  {...inputProps}
                  stepperType={config.stepperType}
                  min={config.min}
                  max={max}
                  inline={config.inline}
                  headerText={applyCopy(headerText)}
                  error={fieldState.error?.message}
                  ariaLabelDecrease={ariaLabelDecrease}
                  ariaLabelIncrease={ariaLabelIncrease}
                />
              );
            }

            if (config.type === 'dropdown') {
              return <DropdownInput {...inputProps} options={options} />;
            }

            if (config.type === 'row') {
              return <RowInput {...inputProps} options={options} renderRow={config.renderRow} />;
            }

            if (config.type === 'date') {
              return (
                <DateInput
                  {...inputProps}
                  onBlur={inputProps.onBlurText}
                  format={config.format}
                  dob={!!config.dob}
                />
              );
            }

            if (config.type === 'phone') {
              return (
                <PhoneInput
                  {...inputProps}
                  onBlur={inputProps.onBlurText}
                  subfields={config.subfields}
                  errors={fieldState?.error?.types}
                />
              );
            }

            if (config.type === 'email') {
              return <EmailInput {...inputProps} onBlur={inputProps.onBlurText} />;
            }

            if (config.type === 'alias') {
              return (
                <AliasInput {...inputProps} combo={config.combo} onBlur={inputProps.onBlurText} />
              );
            }

            if (config.type === 'password') {
              return (
                <PasswordInput
                  {...inputProps}
                  passwordType={config.passwordType}
                  requirements={config.requirements}
                  onBlur={inputProps.onBlurText}
                />
              );
            }

            if (config.type === 'ssn') {
              return <SSNInput {...inputProps} onBlur={inputProps.onBlurText} />;
            }

            if (config.type === 'address') {
              return (
                <AddressInput
                  {...inputProps}
                  disabledFields={disabledFields}
                  errors={fieldState?.error?.types}
                  onBlur={inputProps.onBlurText}
                />
              );
            }

            if (config.type === 'legalName') {
              return (
                <LegalNameInput
                  {...inputProps}
                  subfields={config.subfields}
                  errors={fieldState?.error?.types}
                  onBlur={inputProps.onBlurText}
                />
              );
            }

            if (config.type === 'radio') {
              return (
                <RadioInput
                  {...inputProps}
                  options={options}
                  horizontal={horizontal}
                  ariaLabel={contextualAriaLabel}
                />
              );
            }

            if (config.type === 'person') {
              const Accessory = config.Accessory;

              return (
                <PersonRowInput
                  {...inputProps}
                  inset={config.inset}
                  label={config.personName || label}
                  secondary={secondary}
                  Accessory={Accessory ? () => <Accessory values={values} remove={remove} /> : null}
                />
              );
            }

            if (config.type === 'file') {
              return (
                <FileInput
                  {...inputProps}
                  documentType={documentType}
                  filePrefix={filePrefix}
                  fileTypes={config.fileTypes}
                  inputKey={config.inputKey}
                  skipUpload={config.skipUpload}
                />
              );
            }

            return null;
          }}
        />
        <FieldAddition component={addition?.component} props={addition?.props} />
      </View>
    );
  },
);

Field.displayName = 'Field';
export default Field;
