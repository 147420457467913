import { Route } from '@types';

/**
 * Adding a new stack?
 *
 * Step 1: Add to the StackName type. Follow naming FEATURE_STACK
 * Step 2: Add to the stacks object, e.g. FEATURE_STACK: 'FEATURE_STACK'
 * Step 3: Add to prefixes object (this is implemented for prefixing on web)
 * Step 4: Add child routes to the stackScreens (this allows for nested navigation)
 */

// to allow for lookup like stacks.AUTH_STACK
export const stacks = {
  AUTH_STACK: 'AUTH_STACK',
  ONBOARDING_STACK: 'ONBOARDING_STACK',
  HOME_STACK: 'HOME_STACK',
  COVERAGE_STACK: 'COVERAGE_STACK',
  ME_STACK: 'ME_STACK',
  HEALTH_EXPLORER_STACK: 'HEALTH_EXPLORER_STACK',
  HEALTH_LINK_STACK: 'HEALTH_LINK_STACK',
  HEALTH_APPLICATION_STACK: 'HEALTH_APPLICATION_STACK',
  CIC_STACK: 'CIC_STACK',
  BANKING_SETUP_STACK: 'BANKING_SETUP_STACK',
  GOAL_SETUP_STACK: 'GOAL_SETUP_STACK',
  GOALS_SETUP_STACK: 'GOALS_SETUP_STACK',
  MONEY_STACK: 'MONEY_STACK',
  TRANSFER_STACK: 'TRANSFER_STACK',
  PAYCHECK_STACK: 'PAYCHECK_STACK',
};

// stack name type
export type StackName = keyof typeof stacks;

// added to allow for prefixing in web
export const prefixes: Record<StackName, string> = {
  AUTH_STACK: '/auth',
  ONBOARDING_STACK: '/setup',
  HOME_STACK: '/',
  COVERAGE_STACK: '/coverage',
  ME_STACK: '/me',
  HEALTH_EXPLORER_STACK: '/health/explorer',
  HEALTH_LINK_STACK: '/health/link',
  HEALTH_APPLICATION_STACK: '/health/application',
  CIC_STACK: '/coverage-change/:type',
  BANKING_SETUP_STACK: '/fintech-setup',
  GOAL_SETUP_STACK: '/goal/:slug',
  GOALS_SETUP_STACK: '/goals',
  MONEY_STACK: '/money',
  TRANSFER_STACK: '/transfer',
  PAYCHECK_STACK: '/paycheck',
};

/**
 * allows for nested navigation on native
 * note -- these should match the corresponding stack definitions, there is a
 * test to check for mismatches that is helpful
 */
export const stackScreens: Record<StackName, Array<Route>> = {
  HOME_STACK: [Route.HOME],
  COVERAGE_STACK: [Route.COVERAGE, Route.COVERAGE_DETAIL],
  AUTH_STACK: [Route.CONSENT, Route.LOGIN, Route.REGISTER],
  ONBOARDING_STACK: [Route.ONBOARDING_CONSENT],
  ME_STACK: [Route.ACCOUNT, Route.ACCOUNT_INFO, Route.ACCOUNT_SETTINGS, Route.STATEMENTS],
  HEALTH_EXPLORER_STACK: [
    Route.EXPLORER_INTRO,
    Route.EXPLORER_ZIPCODE,
    Route.EXPLORER_APPLICANTS,
    Route.EXPLORER_STATE_HANDOFF,
    Route.EXPLORER_PARTNER_HANDOFF,
    Route.STATE_RETURN,
    Route.EXPLORER_HOUSEHOLD,
    Route.EXPLORER_SAVINGS,
    Route.EXPLORER_RISK_TOLERANCE,
    Route.EXPLORER_USAGE,
    Route.EXPLORER_PRESCRIPTIONS,
    Route.EXPLORER_DOCTORS,
    Route.EXPLORER_CONCIERGE,
    Route.EXPLORER_SCHEDULE,
    Route.EXPLORER_CHOOSE_PLAN,
    Route.EXPLORER_SEP,
    Route.EXPLORER_SEP_INELIGIBLE,
    Route.EXPLORER_QLE,
    Route.EXPLORER_CONSENT,
    Route.EXPLORER_WINDOW_SHOPPING,
    Route.AETNA_ATTESTATION,
  ],
  HEALTH_LINK_STACK: [
    Route.HEALTH_LINK_INTRO,
    Route.HEALTH_LINK_AGREEMENTS,
    Route.HEALTH_LINK_IDENTITY,
    Route.HEALTH_LINK_IMPORT,
  ],
  HEALTH_APPLICATION_STACK: [
    Route.EDE_INTRO,
    Route.EDE_COVERAGE_INFO,
    Route.EDE_PRIVACY,
    Route.EDE_HOUSEHOLD,
    Route.EDE_SCREENING,
    Route.EDE_PRO_ASSISTANCE,
    Route.EDE_ID_INFO,
    Route.EDE_IDENTITY,
    Route.EDE_APPLICATION_IMPORT,
    Route.EDE_CONFIRM_APPLICANTS,
    Route.EDE_WINDOW_SHOPPING,
    Route.EDE_MEMBER_INFO,
    Route.EDE_MEMBER_QUESTIONS,
    Route.EDE_IMMIGRATION_DETAILS,
    Route.EDE_MEDICAID_DENIAL,
    Route.EDE_MEMBER_INCOME_LIST,
    Route.EDE_MEMBER_DEDUCTIONS_LIST,
    Route.EDE_MEMBER_INCOME_DETAILS,
    Route.EDE_MEMBER_INCOME_CONFIRM,
    Route.EDE_MEMBER_INCOME_DISCREPANCY,
    Route.EDE_SEP,
    Route.EDE_OUTSIDE_HOUSEHOLD,
    Route.EDE_DEPENDENTS_CURRENT_COVERAGE,
    Route.EDE_MEMBER_MEDICAL_BILLS,
    Route.EDE_MEMBER_WORK_HOURS,
    Route.EDE_OTHER_COVERAGE,
    Route.EDE_VOTER_REGISTRATION,
    Route.EDE_REVIEW,
    Route.EDE_AGREEMENTS,
    Route.EDE_RESULTS,
    Route.EDE_SEP_OUT_OF_DATE,
    Route.EDE_SPLIT_ELIGIBILITY,
    Route.EDE_PLANS,
    Route.EDE_APTC_AGREEMENT,
    Route.EDE_ENROLL,
    Route.EDE_SUCCESS,
    Route.EDE_HELP,
    Route.EDE_ALTERNATE_EMAIL,
    Route.HEALTH_DOCS,
    Route.AETNA_EDE_PREFILL_ATTESTATION,
  ],
  CIC_STACK: [
    Route.CIC_MANAGE_COVERAGE_INTRO,
    Route.CIC_CHANGE_SELECTION,
    Route.CIC_BASICS,
    Route.CIC_ADDRESS_ENTRY,
    Route.CIC_NO_CHANGE,
    Route.CIC_STATE_HANDOFF,
    Route.CIC_STATE_PICKUP,
    Route.CIC_CANCEL_POLICY,
    Route.CIC_GOV_HANDOFF,
    Route.CIC_GOV_PICKUP,
    Route.CIC_APP_REVIEW_INTRO,
    Route.CIC_MEMBER_MARRIAGE,
    Route.CIC_MEMBER_CUT_INTRO,
    Route.CIC_MOVE_DATE_ENTRY,
    Route.CIC_MEMBER_DIVORCE,
    Route.CIC_MEMBER_DEATH,
    Route.CIC_MEMBER_CUSTODY,
    Route.CIC_MEMBER_JOB_COVERAGE,
    Route.CIC_MEMBER_STATUS_CHANGE,
    Route.CIC_REMOVING_MEMBERS,
    Route.CIC_POLICY_INVALID,
    Route.CIC_NEW_PLANS_AVAILABLE,
    Route.CIC_NEW_PLAN_REQUIRED,
    Route.CIC_PRELIM_ELIG,
    Route.CIC_MEMBER_CUT,
    Route.CIC_SEP_MIXED,
    Route.CIC_SSN_ENTRY,
    Route.CIC_INCOME_ENTRY,
  ],
  BANKING_SETUP_STACK: [
    Route.BANKING_SETUP_INTRO,
    Route.BANKING_SETUP_NAME,
    Route.BANKING_SETUP_DOB,
    Route.BANKING_SETUP_PHONE,
    Route.BANKING_SETUP_ADDRESS,
    Route.BANKING_SETUP_OCCUPATION,
    Route.BANKING_SETUP_SSN,
    Route.BANKING_SETUP_ADD_BANK_LINK,
    Route.BANKING_SETUP_CONFIRM,
    Route.BANKING_SETUP_DOCUMENT_UPLOAD,
  ],
  GOAL_SETUP_STACK: [
    Route.GOAL_INTRO,
    Route.GOAL_EMPLOYMENT_TYPE,
    Route.GOAL_ESTIMATED_INCOME,
    Route.GOAL_WORK_STATE,
    Route.GOAL_TAX_FILING_STATUS,
    Route.GOAL_SPOUSE_INCOME,
    Route.GOAL_DEPENDENTS,
    Route.GOAL_ESTIMATOR,
    Route.GOAL_CONFIRM,
  ],
  GOALS_SETUP_STACK: [
    Route.GOALS,
    Route.GOALS_ESTIMATED_INCOME,
    Route.GOALS_WORK_STATE,
    Route.GOALS_TAX_FILING_STATUS,
    Route.GOALS_SPOUSE_INCOME,
    Route.GOALS_DEPENDENTS,
    Route.GOALS_ESTIMATOR,
    Route.GOALS_ADDITIONAL,
    Route.GOALS_CONFIRM,
  ],
  MONEY_STACK: [Route.MONEY, Route.GOAL_DETAIL],
  TRANSFER_STACK: [Route.TRANSFER_AMOUNT, Route.TRANSFER_CONFIRM],
  PAYCHECK_STACK: [Route.PAYCHECK_INCOME, Route.PAYCHECK_SKIPPED, Route.PAYCHECK_BREAKDOWN],
};

// looks up the stack based on the actual route (for nested navigation)
export const stackLookup = (route: Route): StackName | false => {
  let matchingStack: StackName | false = false;
  let stackName: StackName;

  for (stackName of Object.keys(stackScreens) as Array<StackName>) {
    if (stackScreens[stackName].includes(route)) {
      matchingStack = stackName;
      break;
    }
  }

  return matchingStack;
};
