import gql from 'graphql-tag';
import {
  PolicyStatusType,
  EnrollmentType,
  IdentityStatus,
  IdentityProvider,
  AgreementSet,
  HealthPathwayType,
  HealthApplicationContext,
  HealthApplicationStatus,
} from '@types';

import { ApolloOpenEnrollmentDates, OpenEnrollmentFragment } from '../fragments';
export interface NudgeData {
  id: string;
  isDismissed: boolean;
}

export interface EnrollmentData {
  id: string;
  status: PolicyStatusType;
  year: number;
  enrollmentType: EnrollmentType;
  policy: {
    id: string;
    startDate: string; // YYYY-MM-DD
    endDate: string; // YYYY-MM-DD
    dueDate: Date;
    providerPlan: {
      id: string;
      type: string;
      issuer: {
        id: string;
        name: string;
      };
    };
  };
}

export interface HomeQueryData {
  viewer: {
    id: string;
    user: {
      id: string;
      joinDate: Date;
      email: string;
      supportedPathways: Array<HealthPathwayType>;
    };
    nudges: Array<NudgeData>;
  };
  viewerTwo: {
    id: string;
    healthExplorerData?: {
      id: string;
      state: string;
      pathwayType?: HealthPathwayType;
    };
    cmsIdentity: {
      id: string;
      status: IdentityStatus;
      providerType: IdentityProvider;
    };
    agreements: AgreementSet;
    health: {
      applications: Array<{
        id: string;

        applicationContext: HealthApplicationContext;
        status: HealthApplicationStatus;
        lastUsedRoute: string;
        coverageState: string;
        coverageYearNumber: number;
        policies: Array<{ id: string }>;
        enrollment: { id: string };
      }>;
    };
    documents: {
      taxDocuments: {
        id: string;
      };
    };
  };
  reference: {
    health: {
      openEnrollmentDates: ApolloOpenEnrollmentDates;
    };
  };
}

export const HomeQuery = gql`
  query HomeQuery($year: Int!, $taxYear: Int!, $prevTaxYear: Int!, $coverageYears: [Int!]) {
    viewer {
      id
      user {
        id
        joinDate: createdOn
        email
        supportedPathways
      }
      nudges {
        id: nudgeIdentifier
        isDismissed
      }
    }
    viewerTwo {
      id
      healthExplorerData {
        id
        state
        pathwayType
        providerPlanID
      }
      cmsIdentity: identity(providerType: CMS) {
        id
        status
        providerType
      }
      existingApplicationInfo {
        existingApplications {
          id
        }
      }
      agreements {
        id
        acknowledgedUnitAccountDisclosures
        authorizedAboundEftpsEnrollment
        isPrivacyDataUseAgree
        isPrivacyTruthfulAgree
      }
      health {
        policies(input: { coverageYearNumber: $coverageYears }) {
          id
          policyStatus
          productType
          startDate
          endDate
          dueDate @client
          coverageYear @client
          carrier
          planName
          shorthand @client
          policyNumber
          subscriberID
          exchangeSubscriberID
          enrollmentID
          providerPlan {
            id
            name
            type
            issuer {
              id
              name
              tollFree
            }
          }
        }
        applications(input: { coverageYearNumber: $coverageYears }) {
          id
          status
          applicationContext
          lastUsedRoute
          coverageState
          coverageYearNumber
          pathwayType
          enrollment {
            id
          }
          policies {
            id
            policyStatus
            productType
            startDate
            endDate
            dueDate @client
            carrier
            planName
            policyNumber
            subscriberID
            exchangeSubscriberID
            providerPlan {
              id
              name
              issuer {
                id
                name
                tollFree
              }
            }
          }
          dmis {
            id
            status
            resolveBy
          }
          svis {
            id
            status
            resolveBy
          }
        }
      }
      # // @todo fix
      documents {
        taxDocuments(
          input: { endMonth: 3, endYear: $taxYear, startMonth: 3, startYear: $prevTaxYear }
        ) {
          id
        }
      }
    }
    reference {
      health {
        openEnrollmentDates {
          ...OEDates
        }
      }
    }
  }
  ${OpenEnrollmentFragment}
`;
