import { navigate, sheets, useSheet } from '@navigate';
import { PromptName, Prompt, PromptContext, Route, Situation, PromptDefinition } from '@types';
import { getCarrierAsset, goToHealthApp, safeFormatDate } from '@app/utils';

import Clock from '@svg/clock.svg';
import Document from '@svg/file-important.svg';
import Statement from '@svg/file.svg';
import Link from '@svg/linked.svg';
import Lock from '@svg/lock.svg';

const DATE_FORMAT = 'MMM d';

export const promptOrder: Record<PromptContext, number> = {
  ACCOUNT: 0,
  HEALTH: 1,
  BLOCKING_CLOCK: 1,
  BLOCKING: 2,
  STATUS: 3,
  INCOME: 4,
  TODO: 5,
};

/**
 *
 * @todo add:
 * - health manual idp in progress
 * - rate the app
 * - rollovers
 * - health effectuation (and rest of OE prompts)
 * - all set
 *
 */
export const Prompts: Record<PromptName, PromptDefinition> = {
  [PromptName.MANUAL_ID_REVIEW]: {
    name: PromptName.MANUAL_ID_REVIEW,
    context: 'ACCOUNT',
    icon: Clock,
    title: 'Documents in review',
    subtitle: "We're reviewing your uploaded identity documents",
    dependencies: [Situation.MANUAL_ID_REVIEW],
  },
  [PromptName.COVERAGE_SETUP]: {
    name: PromptName.COVERAGE_SETUP,
    title: 'No coverage',
    subtitle: 'Get covered today',
    context: 'HEALTH',
    dependencies: [
      Situation.NO_CURRENT_APPS,
      Situation.NO_CURRENT_POLICIES,
      Situation.NOT_WINDOW_SHOPPING,
      Situation.NOT_OPEN_ENROLLMENT,
    ],
    dismissable: false,
    accentColor: 'coverage',
    gradient: 'coverageLight',
    render: 'coverageAlt',
    actions: [{ label: 'Get Started', type: 'ROUTE', route: Route.EXPLORER_INTRO }],
  },
  [PromptName.START_HEALTH_APPLICATION]: {
    name: PromptName.START_HEALTH_APPLICATION,
    context: 'TODO',
    title: 'Get Covered',
    subtitle: 'Looking for health insurance coverage? Start a new application now.',
    dependencies: [Situation.HEALTH_SIMPLE],
    accentColor: 'coverage',
    gradient: 'coverageLight',
    render: 'coverageAlt',
    guide: 'health/marketplace',
    actions: [
      {
        label: 'Get started',
        type: 'ROUTE',
        route: Route.EDE_INTRO,
      },
    ],
  },
  [PromptName.INCOME]: (data) => ({
    forEach: true,
    name: PromptName.INCOME,
    context: 'INCOME',
    title: '(paychecks)',
    dependencies: [Situation.INCOME],
    data,
    actions: [
      {
        label: 'Confirm',
        type: 'ROUTE',
        // route: Route.
      },
    ],
  }),
  [PromptName.WINDOW_SHOPPING]: (data) => {
    const { oeCoverageYear } = data?.reference?.health?.openEnrollmentDates || {};
    const hasProviderPlan = !!data?.viewerTwo?.healthExplorerData?.providerPlanID;
    return {
      name: PromptName.WINDOW_SHOPPING,
      context: 'HEALTH',
      title: hasProviderPlan ? 'Almost there!' : `Shop ${oeCoverageYear} plans`,
      subtitle: hasProviderPlan
        ? 'Come back November 1 to continue enrolling'
        : `Explore today, enroll on Nov. 1`,
      accentColor: 'coverage',
      gradient: 'coverageLight',
      render: 'coverageAlt',
      dependencies: [Situation.WINDOW_SHOPPING, Situation.NO_CURRENT_APPS],
      actions: hasProviderPlan
        ? []
        : [
            {
              label: 'Pick your plan',
              type: 'ROUTE',
              route: Route.EXPLORER_INTRO,
            },
          ],
    };
  },
  [PromptName.OPEN_ENROLLMENT]: (data) => {
    const { oeCoverageYear } = data?.reference?.health?.openEnrollmentDates || {};

    return {
      name: PromptName.OPEN_ENROLLMENT,
      context: 'HEALTH',
      title: `Get ${oeCoverageYear} Health Insurance`,
      subtitle: 'Find a plan or renew your current coverage',
      accentColor: 'coverage',
      gradient: 'coverageLight',
      render: 'coverageAlt',
      guide: 'health/oep',
      dependencies: [
        Situation.ASSUMED_FFM,
        Situation.NO_CURRENT_APPS,
        Situation.NO_CURRENT_POLICIES,
        Situation.OPEN_ENROLLMENT,
      ],
      actions: [
        {
          label: 'Get started',
          type: 'ROUTE',
          route: Route.EXPLORER_INTRO,
        },
      ],
    };
  },
  [PromptName.NON_FFM_OE]: (data) => ({
    name: PromptName.NON_FFM_OE,
    context: 'HEALTH',
    title: 'Explore health plans',
    subtitle: `We'll help you browse plans in ${
      data?.viewerTwo?.healthExplorerData?.state || 'your state'
    }`,
    accentColor: 'coverage',
    gradient: 'coverageLight',
    render: 'coverageAlt',
    guide: 'health/oep',
    dependencies: [
      Situation.KNOWN_NON_FFM,
      Situation.NO_CURRENT_APPS,
      Situation.NO_CURRENT_POLICIES,
      Situation.OPEN_ENROLLMENT,
    ],
    actions: [
      {
        label: 'Explore plans',
        type: 'ROUTE',
        route: Route.EXPLORER_INTRO,
      },
    ],
  }),
  [PromptName.CONTINUE_APP]: (data) => {
    const windowShoppingCompleted =
      data?.app?.lastUsedRoute === 'confirm-applicants' && data?.oeDates?.isWindowShopping;

    return {
      forEach: true,
      name: PromptName.CONTINUE_APP,
      context: 'HEALTH',
      title: windowShoppingCompleted ? 'Almost there!' : 'Complete your health application',
      subtitle: windowShoppingCompleted
        ? `Come back November 1 to finish enrolling in ${data?.app?.coverageYearNumber} coverage`
        : `Just a few more steps to finish your ${data?.app?.coverageYearNumber} application`,
      accentColor: 'coverage',
      gradient: 'coverageLight',
      render: 'coverageAlt',
      dependencies: [Situation.HAS_NOT_ENROLLED],
      actions: windowShoppingCompleted
        ? []
        : [
            {
              label: 'Continue',
              type: 'CUSTOM',
              onAction: () => goToHealthApp(data?.app),
            },
          ],
    };
  },
  [PromptName.FINISH_ENROLLING]: (data) => ({
    forEach: true,
    name: PromptName.FINISH_ENROLLING,
    context: 'HEALTH',
    title: 'Almost done',
    subtitle: 'Finalize your plan selection',
    accentColor: 'coverage',
    gradient: 'coverageLight',
    render: 'coverageAlt',
    dependencies: [Situation.HAS_NOT_ENROLLED, Situation.OPEN_ENROLLMENT],
    actions: [
      {
        label: 'Enroll',
        type: 'CUSTOM',
        onAction: () => goToHealthApp(data?.app),
      },
    ],
  }),

  [PromptName.NEEDS_HEALTH_DOCS]: (data) => ({
    forEach: true,
    name: PromptName.NEEDS_HEALTH_DOCS,
    context: 'BLOCKING',
    title: data?.actionNeeded ? 'Finalize your coverage' : 'Your docs are pending',
    subtitle: data?.actionNeeded
      ? `Upload required documentation by ${safeFormatDate(data?.deadline, DATE_FORMAT)}`
      : 'Your docs are awaiting review',
    accentColor: 'coverage',
    gradient: 'coverageLight',
    icon: Document,
    dependencies: [],
    actions: [
      {
        label: data?.actionNeeded ? 'Upload' : 'View',
        type: 'CUSTOM',
        onAction: () => navigate(Route.HEALTH_DOCS, { applicationID: data?.applicationID }),
      },
    ],
  }),
  [PromptName.PAYMENT_REDIRECT]: (data) => ({
    forEach: true,
    name: PromptName.PAYMENT_REDIRECT,
    context: 'BLOCKING',
    title: `Make your first payment`,
    article: 'https://help.catch.co/en/articles/4543944-how-do-i-pay-my-health-premium',
    subtitle: `Make a payment by ${safeFormatDate(
      data?.policy?.dueDate,
      DATE_FORMAT,
    )} to confirm and activate your ${
      data?.policy?.productType?.includes('DENTAL') ? 'dental' : 'health'
    } coverage`,
    accentColor: 'coverage',
    gradient: 'coverageLight',
    asset: getCarrierAsset(
      data?.policy?.carrier || data?.policy?.providerPlan?.issuer?.name,
      data?.policy?.productType,
      'md',
    ),
    dependencies: [],
    data,
    actions: [
      {
        label: 'Pay premium',
        type: 'CUSTOM',
        onAction: () =>
          useSheet
            .getState()
            .open(sheets.PAYMENT_REDIRECT, { policy: data?.policy, enrollment: data?.enrollment }),
      },
    ],
  }),

  [PromptName.DOCS_AVAILABLE_2]: {
    name: PromptName.DOCS_AVAILABLE_2,
    context: 'BLOCKING',
    title: '2022 Documents',
    subtitle: 'Annual tax documents are ready to download',
    dependencies: [Situation.DOCS_AVAILABLE],
    icon: Statement,
    actions: [
      {
        label: 'View',
        type: 'ROUTE',
        route: Route.STATEMENTS,
      },
    ],
  },

  [PromptName.SAVINGS_SETUP]: {
    name: PromptName.SAVINGS_SETUP,
    title: 'Set up savings',
    context: 'TODO',
    dependencies: ['MANUAL'],
    dismissable: false,
    accentColor: 'saved',
    gradient: 'savedLight',
    render: 'savedAlt',
    subtitle: 'Set aside for time off, emergency savings, and more',
    article: 'https://help.catch.co/en/collections/734399-building-for-retirement',
  },

  [PromptName.TAXES_SETUP]: {
    name: PromptName.TAXES_SETUP,
    title: 'Set up taxes',
    context: 'TODO',
    dependencies: ['MANUAL'],
    dismissable: false,
    accentColor: 'taxes',
    gradient: 'taxesLight',
    render: 'taxesAlt',
    guide: 'tax/entity',
    subtitle: 'Withhold for federal and state taxes',
    article:
      'https://help.catch.co/en/articles/2129934-how-does-catch-help-me-with-my-tax-withholding',
    actions: [{ label: 'Get Started' }],
  },

  [PromptName.ACCOUNT_LOCKED]: {
    name: PromptName.ACCOUNT_LOCKED,
    context: 'ACCOUNT',
    title: 'Your account is locked',
    subtitle: 'Contact support for assistance',
    icon: Lock,
    gradient: 'negativeLight',
    dependencies: ['LOCKED'],
    actions: [
      {
        label: 'Support',
        type: 'HELP',
      },
    ],
  },
  [PromptName.KYC_REVIEW]: {
    name: PromptName.KYC_REVIEW,
    context: 'ACCOUNT',
    icon: Clock,
    title: 'Your account is almost ready',
    subtitle: "We're just reviewing a few final details",
    dependencies: ['KYC_PENDING'],
    article: 'https://help.catch.co/en/articles/2130140-what-is-kyc',
  },
  [PromptName.LINK_BANK]: {
    name: PromptName.LINK_BANK,
    context: 'BLOCKING',
    title: 'Connect your bank account',
    subtitle: 'Connect your account to set aside for taxes, retirement, health, and more',
    icon: Link,
    gradient: 'brandLight',
    dependencies: ['NO_BANK'],
    article: 'https://help.catch.co/en/articles/2034414-why-do-i-need-to-link-my-bank-account',
    actions: [
      {
        label: 'Link',
        type: 'CUSTOM',
        onAction: (props) => launchPlaid({ mode: 'create', setLoading: props.setLoading }),
      },
    ],
  },
};

export const PromptList: Prompt[] = Object.values(Prompts);
