import React from 'react';
import { FormConfig, Fields, useForm } from '@f';
import { Banner, Button, useResponsive } from '@uikit';
import { BasicLayout, BasicLayoutProps, Toolbar, useLayoutContext } from '@layouts';
import { Copy } from '@app/types';
import { FormHookResponse } from '@app/forms/types';
import { useCopy } from '@app/utils';

// note: shares this with other form blueprints! how cool
export interface BasicFormBlueprintProps<TFormValues> {
  loading: boolean; // shows a loader during initial load
  submitting: boolean; // while submitting the form, disable all buttons and inputs
  optional?: boolean;
  icon?: BasicLayoutProps['icon'];
  title?: Copy;
  subtitles?: Copy[];
  subtitle?: Copy;
  button?: Copy;
  dynamicButton?: (form: FormHookResponse<TFormValues>) => Copy;
  formConfig: FormConfig<TFormValues>;
  precomponent?: React.ReactNode;
  precontent?: React.ReactNode;
  postContent?: React.ReactNode;
  error?: string;
  toolbar?: (props: {
    handleSubmit: () => void;
    disabled: boolean;
    submitting: boolean;
  }) => React.ReactNode;
  onBack?: () => void;
}

export const BasicFormBlueprint = <TFormValues extends Record<string, any> = Record<string, any>>({
  loading, // for initial load
  submitting, // for example when submitting the form
  optional,
  icon,
  title,
  subtitle,
  subtitles,
  button,
  dynamicButton,
  formConfig,
  precomponent, //component before title
  precontent, //component after title but above content
  postContent,
  error,
  toolbar,
  onBack,
}: BasicFormBlueprintProps<TFormValues>) => {
  const { layout } = useLayoutContext();
  const { isDesktop } = useResponsive();
  const { c } = useCopy('catch.basics');

  const form = useForm<TFormValues>({
    loading,
    disabled: submitting,
    fields: formConfig.fields,
    initialValues: formConfig.initialValues,
    onSubmit: formConfig.onSubmit,
  });

  return (
    <BasicLayout
      loading={loading}
      pretitle={optional ? c('optional') : undefined}
      precomponent={precomponent}
      icon={icon}
      title={title}
      subtitles={subtitles || [subtitle]}
      style={layout === 'page' && !isDesktop && { paddingBottom: 100 }}
      toolbar={
        toolbar ? (
          toolbar({
            handleSubmit: form.submitForm,
            disabled: form.disableSubmit,
            submitting,
          })
        ) : (
          <Toolbar type={onBack ? 'flow' : 'stack'} onBack={onBack}>
            <Button testID="next" inherit onPress={form.submitForm} disabled={form.disableSubmit}>
              {button || (dynamicButton ? dynamicButton(form) : c('next'))}
            </Button>
          </Toolbar>
        )
      }
    >
      {precontent}
      <Fields form={form} fields={formConfig.fields} stackProps={{ bottomSpace: true }} />
      {error && <Banner bg="negativeLight" title={error} />}
      {postContent}
    </BasicLayout>
  );
};

export default BasicFormBlueprint;
