import React from 'react';

import { useCopy, handleHealthResponse, Segment, SegmentEvent } from '@app/utils';
import { HealthApplicationQuery, mutations, useMutation, useQuery } from '@data';
import { useTextOptIn } from '@hooks';
import { navigate, sheets, useSheet } from '@navigate';
import { SplitFormBlueprint } from '@app/blueprints';
import { fields } from './idInfoFields';
import { getInitialValues, formatPayload } from './idInfoUtils';
import { useExistingApps } from '@app/hooks/useExistingApps';
import { logGQLError } from '@app/utils/logger';
import { Route } from '@app/types';
import { shallow } from 'zustand/shallow';

const IdInfo = ({ isGA, applicationID, applicantID, enrollId }) => {
  const { openSheet } = useSheet((state) => ({ openSheet: state.open }), shallow);
  const { c } = useCopy('catch.ede');

  const { data } = useQuery(HealthApplicationQuery, {
    variables: { applicationID },
    skip: !applicationID,
  });

  const { checking, checkForExistingApplications } = useExistingApps(applicationID);

  const [upsert, { loading: upserting }] = useMutation(
    mutations.UPSERT_APPLICATION_MEMBERS,
    handleHealthResponse({}),
  );

  const { update } = useTextOptIn();

  const handleContinue = () => {
    Segment.track(SegmentEvent.APPLICATION_APPLICANT_ADDED, {
      coverage_year: data?.viewerTwo?.health?.application?.coverageYearNumber,
    });

    if (data?.viewerTwo?.identity?.status !== 'VERIFIED') {
      navigate(Route.EDE_IDENTITY);
    } else {
      // check if applications exist
      checkForExistingApplications();
    }
  };

  const onSubmit = async (values) => {
    if (values?.textOptIn === true) {
      update();
      Segment.track(SegmentEvent.AGREEMENT_SIGNED, {
        agreement_type: 'TEXT_CONSENT',
      });
    }

    if (values?.id) {
      const payload = formatPayload({ applicationID, values });
      await upsert(payload);
      handleContinue();
    } else {
      logGQLError(
        { message: 'No ID for Health User' },
        { operationName: 'UpsertApplication' },
        {
          tags: { product: 'health', feature: 'enrollment' },
        },
      );
      alert('no id for this user is present, ask for technical assistance');
    }
  };

  // wait for SSN confirmation when non-applicants dont supply SSN
  const shouldWaitForSSNConfirmation = (values) => {
    return values.isRequestingCoverage === 'NO' && !values.ssn;
  };

  const showMissingSSN = (_, { handleNext }) => {
    openSheet(sheets.MISSING_HEALTH_SSN, { onContinue: handleNext });
  };

  return (
    <SplitFormBlueprint
      loading={!data}
      submitting={upserting || checking}
      title={c('IdInfo.title')}
      subtitle={c('IdInfo.subtitle')}
      formConfig={{
        initialValues: getInitialValues(data, isGA),
        fields,
        onSubmit: (values) => onSubmit(values),
      }}
      onWait={showMissingSSN}
      shouldWait={shouldWaitForSSNConfirmation}
    />
  );
};

export const IdInfoView = {
  name: Route.EDE_ID_INFO,
  component: IdInfo,
  options: {
    ...SplitFormBlueprint.options,
    title: 'Contact info',
  },
};
