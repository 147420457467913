import { useHealthStore } from '@hooks/useHealthStore';

type HealthContext = 'FFM' | 'GA';

const marketplaceNames: Record<
  HealthContext,
  { name: string; the: string; full: string; site: string }
> = {
  FFM: {
    name: 'Marketplace',
    the: 'the Marketplace',
    full: 'the Health Insurance Marketplace',
    site: 'Healthcare.gov',
  },
  GA: {
    name: 'Georgia Access',
    the: 'Georgia Access',
    full: 'Georgia Access',
    site: 'Georgia Access',
  },
};

const providers: Record<HealthContext, string> = {
  FFM: 'Marketplace',
  GA: 'Catch',
};

const chipNames: Record<HealthContext, string> = {
  FFM: 'CHIP',
  GA: 'PeachCare for Kids®',
};

const fullChipNames: Record<HealthContext, string> = {
  FFM: 'the Children’s Health Insurance Prorgam (CHIP)',
  GA: 'PeachCare for Kids®',
};
const medicaidNames: Record<HealthContext, string> = {
  FFM: 'Medicaid',
  GA: 'Georgia Medicaid',
};

const callCenter: Record<HealthContext, string> = {
  FFM: 'the Marketplace Call Center at 1-800-318-2596 (TTY: 1-855-889-4325)',
  GA: 'Catch at (833) 309-0500',
};

const assistanceURL: Record<HealthContext, string> = {
  FFM: 'LocalHelp.HealthCare.gov',
  GA: 'https://georgiaaccess.gov/resources-and-support/find-a-local-assister',
};

const agentLookupURL: Record<HealthContext, string> = {
  FFM: 'LocalHelp.HealthCare.gov',
  GA: 'https://GeorgiaAccess.gov/get-covered/agent',
};

const nativeMedicaidChip: Record<HealthContext, string> = {
  FFM: 'https://HealthCare.gov/American-Indians-Alaska-natives/Medicaid-chip/',
  GA: 'https://help.georgiaaccess.gov/hc/en-us/articles/16717985630356-Does-Georgia-Medicaid-and-PeachCare-for-Kids-offer-health-coverage-for-American-Indians-and-Alaska-Natives',
};
const householdSize: Record<HealthContext, string> = {
  FFM: 'https://www.healthcare.gov/income-and-household-information/household-size/',
  GA: 'https://help.georgiaaccess.gov/hc/en-us/articles/16717957110292-Who-do-I-include-in-my-household',
};

export const useUniversalCopy = () => {
  const { context } = useHealthStore();

  return {
    marketplaceName: marketplaceNames[context].name,
    theMarketplace: marketplaceNames[context].full,
    theShortMarketplace: marketplaceNames[context].the,
    marketplaceSite: marketplaceNames[context].site,
    provider: providers[context],
    callCenter: callCenter[context],
    chipName: chipNames[context],
    fullChipName: fullChipNames[context],
    medicaidName: medicaidNames[context],
    assistanceURL: assistanceURL[context],
    agentLookupURL: agentLookupURL[context],
    nativeMedicaidChip: nativeMedicaidChip[context],
    householdSize: householdSize[context],
    isGA: context === 'GA' ? 'YES' : 'NO',
  };
};
